import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    statuses: [
      {
        value: 0,
        text: 'Disabled'
      },
      {
        value: 1,
        text: 'Enabled'
      }
    ]
  },
  mutations: {},
  actions: {},
  modules: {
    auth
  }
})
