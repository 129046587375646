import auth from '@/auth'
import axios from '@/axios'
import cookie from 'js-cookie'

export default {
  state: {
    user: null
  },
  mutations: {
    SET_USER (state, payload) {
      state.user = payload
    },
    SET_BEARER (state, payload) {
      if (payload) {
        axios.defaults.headers.common.Authorization = `Bearer ${payload}`
      }
    },
    SET_COOKIE_TOKEN (state, payload) {
      if (payload) {
        cookie.set('access_token', payload, {
          secure: false,
          sameSite: 'strict',
          expires: new Date(new Date().getTime() + process.env.VUE_APP_ACCESS_TOKEN_EXPIRE)
        })
      }
    },
    SET_COOKIE_REFRESH_TOKEN (state, payload) {
      if (payload) {
        cookie.set('refresh_token', payload, {
          secure: false,
          sameSite: 'strict',
          expires: new Date(new Date().getTime() + process.env.VUE_APP_REFRESH_TOKEN_EXPIRE)
        })
      }
    }
  },
  actions: {
    async attempt ({ commit, dispatch }, payload) {
      await auth.init()
      if (payload) {
        await axios.get('user', { headers: { Authorization: `Bearer ${payload}` } }).then(response => {
          if (response.data) {
            commit('SET_BEARER', payload)
            commit('SET_USER', response.data)
          }

          if (window.location.pathname === '/login') {
            window.location = '/dashboard'
          }
        }).catch(error => error)
      } else {
        if (cookie.get('refresh_token')) {
          await dispatch('fetchAccessToken')
        } else {
          if (window.location.pathname !== '/login') {
            window.location = '/login'
          }
        }
      }
    },
    async login ({ commit, dispatch }, payload) {
      return await auth.login(payload.email, payload.password).then(response => {
        if (response.data) {
          commit('SET_COOKIE_TOKEN', response.data.access_token)
          commit('SET_COOKIE_REFRESH_TOKEN', response.data.refresh_token)
          commit('SET_BEARER', response.data.access_token)
        }

        return response
      }).catch(error => error)
    },
    async fetchAccessToken ({ commit }) {
      return await auth.refreshToken().then(response => {
        if (response.data) {
          commit('SET_BEARER', response.data.access_token)
          commit('SET_COOKIE_TOKEN', response.data.access_token)
          commit('SET_COOKIE_REFRESH_TOKEN', response.data.refresh_token)

          axios.get('user').then(response => {
            commit('SET_USER', response.data)
          }).catch(error => error)

          return response.data.access_token
        }
      }).catch(() => {
        cookie.remove('access_token')
        cookie.remove('refresh_token')

        if (window.location.pathname !== '/login') {
          window.location = '/login'
        }
      })
    },
    async logout ({ commit }) {
      await auth.logout().then(() => {
        commit('SET_BEARER', null)
        cookie.remove('access_token')
        cookie.remove('refresh_token')
      })
    }
  }
}
