import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import * as VeeValidate from 'vee-validate'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n'
import cookie from 'js-cookie'
import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

// Validator
Vue.use(VeeValidate)

// Clipboard
Vue.use(VueClipboard)

Vue.prototype.statusText = function (status) {
  if (status) return 'Enabled'
  return 'Disabled'
}

Vue.prototype.statusColor = function (status) {
  if (status === 1) return 'success'
  if (status === 2) return 'light'
  return 'error'
}

Vue.config.productionTip = false

// eslint-disable-next-line no-extend-native
Date.prototype.getWeekNumber = function () {
  const d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()))
  const dayNum = d.getUTCDay() || 7
  d.setUTCDate(d.getUTCDate() + 4 - dayNum)
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
}

store.dispatch('attempt', cookie.get('access_token')).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
