export default {
  lv: {
    events: {},
    fields: {},
    validation: {},
    pages: {
      dashboard: {
        title: 'Vadības panelis'
      }
    }
  }
}
