// eslint-disable-next-line no-unused-vars
import axios from '@/axios'
// eslint-disable-next-line no-unused-vars
import cookie from 'js-cookie'
import store from '@/store'

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

export default {
  async init () {
    await axios.interceptors.response.use((response) => {
      return response
    }, (error) => {
      const { config, response } = error
      const originalRequest = config

      if (response && response.status === 401) {
        if (cookie.get('refresh_token')) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true
            store.dispatch('fetchAccessToken')
              .then((accessToken) => {
                isAlreadyFetchingAccessToken = false
                onAccessTokenFetched(accessToken)
              })
          }

          return new Promise((resolve) => {
            addSubscriber(accessToken => {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`
              resolve(axios(originalRequest))
            })
          })
        }
      }
      return Promise.reject(response)
    })
  },
  async login (email, password) {
    return await axios.post('login', {
      email,
      password,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET
    })
  },
  async refreshToken () {
    return await axios.post('refresh-token', {
      refresh_token: cookie.get('refresh_token'),
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET
    })
  },
  async logout () {
    return await axios.post('logout')
  }
}
