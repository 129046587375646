<template>
  <router-view/>
</template>

<script>
export default {}
</script>

<style lang="scss">
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
  color: var(--v-primary-base) !important;
}
</style>
