import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translate from './translate'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'lv',
  messages: translate
})
