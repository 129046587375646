import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import('@/layouts/Main'),
    children: [
      {
        path: '/',
        redirect: '/dashboard'
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/home/Dashboard')
      },
      {
        path: '/budgets',
        name: 'budgetIndex',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/budgets/Index')
      },
      {
        path: '/companies',
        name: 'companyIndex',
        component: () => import('@/views/pages/companies/Index')
      },
      {
        path: '/companies/create',
        name: 'companyCreate',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/companies/Create')
      },
      {
        path: '/companies/show/:id',
        name: 'companyShow',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/companies/Show')
      },
      {
        path: '/companies/edit/:id',
        name: 'companyEdit',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/companies/Edit')
      },
      {
        path: '/advertisements',
        name: 'advertisementIndex',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/advertisements/Index')
      },
      {
        path: '/advertisements/create',
        name: 'advertisementCreate',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/advertisements/Create')
      },
      {
        path: '/advertisements/show/:id',
        name: 'advertisementShow',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/advertisements/Show')
      },
      {
        path: '/advertisements/edit/:id',
        name: 'advertisementEdit',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/advertisements/Edit')
      },
      {
        path: '/tasks',
        name: 'taskIndex',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/tasks/Index')
      },
      {
        path: '/tasks/create',
        name: 'taskCreate',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/tasks/Create')
      },
      {
        path: '/tasks/show/:id',
        name: 'taskShow',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/tasks/Show')
      },
      {
        path: '/tasks/edit/:id',
        name: 'taskEdit',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/tasks/Edit')
      },
      {
        path: '/invoices',
        name: 'invoiceIndex',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/invoices/Index')
      },
      {
        path: '/invoices/create',
        name: 'invoiceCreate',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/invoices/Create')
      },
      {
        path: '/invoices/show/:id',
        name: 'invoiceShow',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/invoices/Show')
      },
      {
        path: '/invoices/edit/:id',
        name: 'invoiceEdit',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/invoices/Edit')
      },
      {
        path: '/users',
        name: 'userIndex',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/users/Index')
      },
      {
        path: '/users/create',
        name: 'userCreate',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/users/Create')
      },
      {
        path: '/users/show/:id',
        name: 'userShow',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/users/Show')
      },
      {
        path: '/users/edit/:id',
        name: 'userEdit',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/users/Edit')
      },
      {
        path: '/log',
        name: 'logIndex',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/logs/Index')
      },
      {
        path: '/keywords',
        name: 'keywordsIndex',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/pages/tools/keywords/Index')
      }
    ]
  },
  {
    path: '',
    component: () => import('@/layouts/Auth'),
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/auth/Login')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
